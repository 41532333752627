
// Connect Sass
require("../scss/index.scss");

// SVG Sprite
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../images/sprite', true, /\.svg$/));

// Loading
window.addEventListener('load', () => {
  document.body.classList.remove('before-load');

  document.querySelector('.loading').addEventListener('transitionend', e => {
    document.body.removeChild(e.currentTarget);
  });
});

// jQuery
window.jQuery = window.$ = require("jquery");

// Select Box
window.SelectBox = require("@toast-ui/select-box");
require("@toast-ui/select-box/dist/toastui-select-box.min.css");
export const basicTheme = {
  'common.backgroundColor': '#fff',

  // Style - form-select.scss
  // input icon, focus, error

  // Input when dropdown is open
  'input.open.borderColor': '#ED5D2A',
  'input.open.color': '#373737',
  'input.open.fontWeight': '600',

  // Input when selectbox is disabled
  'input.disabled.borderColor': '#F0F0F0',
  'input.disabled.backgroundColor': '#FBFBFB',
  'input.disabled.color': '#C0C0C0',

  // Dropdown
  'dropdown.maxHeight': '274px',
  'dropdown.marginTop': '3px',
  'dropdown.padding': '3px',
  'dropdown.border': '0.5px solid #EDEDED;',
  'dropdown.borderRadius': '4px',
  'dropdown.boxShadow': '0px 1px 4px rgba(33, 33, 52, 0.1)',

  // Item
  'item.width': '100%',
  'item.height': '44px',
  'item.fontSize': '14px',
  'item.color': '#111',
  'item.padding': '0 16px',

  // selected Item
  'item.selected.color': '#373737',
  'item.selected.fontWeight': '600',
  'item.selected.backgroundColor': '#FFF !important',

  // highlighted Item
  'item.highlighted.borderRadius': '4px',
  'item.highlighted.backgroundColor': '#EFFBFA',
}

// TOAST Pagination
window.Pagination = require("tui-pagination");

//TOAST Date Picker
window.DatePicker = require("tui-date-picker");
require("tui-date-picker/dist/tui-date-picker.min.css");


//gnb, bookmark event
const body = document.querySelector("body");
/*
const headerMenu = document.querySelector(".header__menu");
const gnbBtn = document.querySelector(".gnbBtn");
const gnbWrap = document.querySelector(".gnb__wrap");
const gnbList = document.querySelector(".gnb__list");
const gnbItem = document.querySelector(".gnb__item");
const gnbItemAll = document.querySelectorAll(".gnb__item");
const gnb2depth = document.querySelector(".gnb__2depthWrap");
const gnbBg = document.querySelector(".gnb__bg");
const bookmarkBtn = document.querySelector(".bookmark__btn");
const bookmarkWrap = document.querySelector(".bookmark__wrap");

gnbBtn.addEventListener('click', (event) => {
    body.classList.toggle('gnb_on');
    headerMenu.classList.toggle('on');	
    if(headerMenu.classList.contains('on')){
      gnbWrap.classList.remove("bm_on");
    }
  });
  [...gnbList.children].forEach((e) => {
    e.addEventListener('mouseenter', (event) => {
      gnbItemAll.forEach((element, index)=>{
        gnbItemAll[index].classList.remove('ov');
      });
      e.classList.add('ov');
      if(gnbWrap.classList.contains('bm_on')){
        gnbWrap.classList.remove('bm_on');
      }
    });
    e.addEventListener('mouseleave', (event) => {
      e.classList.remove('ov');
    });
  });
  
  bookmarkBtn.addEventListener('click', function(){
    gnbWrap.classList.toggle('bm_on');
    gnbItem.classList.remove("ov");
  });
  [...bookmarkWrap.children].forEach((e) => {
    e.addEventListener('mouseenter', (event) => {
      e.classList.add('ov');
      
    });
    e.addEventListener('mouseleave', (event) => {
      e.classList.remove('ov');
    });
  //gnb, bookmark bg 클릭시 모두 닫힘
  gnbBg.addEventListener('click', function(){
    headerMenu.classList.remove('on');	
    gnbItemAll.classList.remove('ov');
    gnbWrap.classList.remove('bm_on');
    gnbItem.classList.remove("ov");
  });
});
*/

// input, search clear
let inputClear = () => {
  let formControlInput = $(".formControl__input,.formControl__search");
  let formControlClear = $(".formControl__clear");
  let searchBtn = $(".formControl__searchBtn");
  formControlInput
    .on("input propertychange", function () {
      var $this = $(this);
      var visible = Boolean($this.val());
      $this.siblings(formControlClear).not(searchBtn).toggleClass("display-none", !visible);
      //$this.toggleClass("is-defalut", !visible);
    })
    .trigger("propertychange");
  formControlClear.on("click", function () {
    $(this).siblings("input").val("").trigger("propertychange").focus();
  });
};

//overlayscrollbars
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';

// href - Tab
function hrefTab() {
  if($('[href^="#tab"]').length) {
    var tabTrigger = $('[href^="#tab"]');

    tabTrigger.each(function() {
      var target  = $($(this).attr('href'));

      $(this).on('click', function(event) {
        $(this).parents('.tab__item').addClass('is-active');
        tabTrigger.not(this).parents('.tab__item').removeClass('is-active');

        target.addClass('is-active');
        target.siblings('.tab__content').removeClass('is-active');

        event.preventDefault();
      });
    });
  }
}

// DOMContentLoaded
window.addEventListener("DOMContentLoaded", function () {
  inputClear();
  hrefTab();
});
